<template>
    <div v-if="quiz && quiz.questions" class="quiz-route-container">
        <div class="route-content">
            <div class="desktop-only">
                <span class="points-text-1 mr-2"><font-awesome-icon :icon="[ 'fas', 'plus-circle' ]" /></span><span class="points-text-2">{{ task.points }}</span><span class="ml-2 points-text-1">{{ $t('quiz.points') }}</span>
            </div>
            <div class="quiz-box">
                <div v-if="task.taskType.alias === 'QUIZ_VIDEO' && !showQuestions">
                    <vue-plyr
                        ref="videoPlayer"
                        :key="rerenderIndex"
                        :options="options"
                        :emit="['ended']"
                        @ended="videoHasEnded()"
                    >
                        <div v-if="quiz.youtubeVideoId" data-plyr-provider="youtube" :data-plyr-embed-id="quiz.youtubeVideoId" />
                        <div v-else-if="quiz.vimeoVideoId" data-plyr-provider="vimeo" :data-plyr-embed-id="quiz.vimeoVideoId" />
                        <video
                            v-else
                            class="video"
                            allowfullscreen
                            allowtransparency
                            :options="options"
                        >
                            <source :src="quiz.externalVideoUrl" type="video/mp4">
                        </video>
                    </vue-plyr>
                </div>
                <div v-if="showQuestions">
                    <span class="question-number-head desktop">{{ task.alias }}</span>
                    <span class="question-number-head mobile">{{ $t('quiz.question') }} {{ activeQuestionIndex+1 }} / {{ quiz.questions.length }}</span>
                    <div v-for="(question, questionIndex) of quiz.questions" v-show="questionIndex === activeQuestionIndex" :key="question.id">
                        <div class="question-header">
                            {{ question.content }}
                        </div>
                        <div
                            v-for="(answer) of question.answers"
                            :key="answer.id"
                            :class="{ 'image-answer-container': task.taskType.alias === 'QUIZ_IMAGE' }"
                            @click="selectAnswer(answer, questionIndex, quiz.questions.length)"
                        >
                            <quiz-answer
                                :answer="answer"
                                :is-correct="answer.correct"
                                :is-answer-visible="answerIsVisible(answer)"
                                :class="{'not-clickable': selectedAnswer}"
                            />
                        </div>
                    </div>
                </div>
                <button
                    v-if="showContinueAfterVideo"
                    class="float-right mt-2 button"
                    @click="continueAfterVideoWithQuestions()"
                >
                    {{ $t('quiz.continueAfterVideo') }}
                </button>
                <div v-if="showQuestions" class="next-button-container">
                    <button v-if="finishedQuestionsCount < quiz.questions.length - 1" class="button" @click="next">
                        {{ $t('quiz.next') }}
                    </button>
                    <button
                        v-else
                        class="button"
                        :class="{ 'disabled': finishedQuestionsCount === quiz.questions.length }"
                        @click="submitPoints(false)"
                    >
                        {{ $t('quiz.finish') }}
                    </button>
                </div>
                <div v-if="showQuestions" class="progress-bar-container">
                    <div class="progress-bar-background">
                        <div
                            class="progress-color"
                            :style="{ width: progressBars(finishedQuestionsCount, quiz.questions.length) }"
                        />
                    </div>
                    <div class="text-left quiz-text desktop-only"><span>{{ finishedQuestionsCount }} / {{ quiz.questions.length }}</span></div>
                </div>
            </div>
            <div class="quiz-instructions desktop-only">
                <div class="owl-bg">
                    <owl-svg />
                </div>
                <span class="instructions-text">{{ task.instructions }}</span>
            </div>
        </div>

    </div>
</template>

<script>
    import config from '@/mixins/config'
    import QuizAnswer from '@/components/QuizAnswer'
    import OwlSvg from '@/components/svg/OwlSVG'
    import quizService from '@/api/quiz'
    import missionService from '@/api/mission'
    import eventBus from '@/utils/event-bus'
    export default {
        name: 'Quiz',
        components: { QuizAnswer, OwlSvg },
        mixins: [config],
        data() {
            return {
                activeQuestionIndex: 0,
                correctAnswers: 0,
                showQuestionsAfterVideo: false,
                showContinueAfterVideo: false,
                quiz: {},
                youTubeVideo: null,
                vimeoTubeVideo: null,
                externalVideo: null,
                selectedAnswer: null,
                finishedQuestionsCount: 0,
                options: { 'controls': [],
                           'keyboard': { focused: false, global: false },
                           'youtube': { rel: 0, showinfo: 0, controls: 0, modestbranding: 1 },
                           'muted': false,
                           'resetOnEnd': true },
                rerenderIndex: 1,
                videoHasEndedOnce: false
            }
        },
        computed: {
            videoPlayer() {
                return this.$refs.videoPlayer
            },
            showQuestions() {
                return (this.task.taskType.alias === 'QUIZ_VIDEO' && this.showQuestionsAfterVideo) || this.task.taskType.alias !== 'QUIZ_VIDEO'
            },
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            }
        },
        created() {
            this.initQuiz()
            eventBus.$on('timeEnded', (payload) => {
                if (this.task && this.task.id === payload.taskId) {
                    this.submitPoints(true)
                }
            })
        },
        beforeDestroy() {
            eventBus.$off('timeEnded')
        },
        methods: {
            videoHasEnded() {
                if (!this.videoHasEndedOnce) {
                    this.options.controls = ['progress', ...this.options.controls]
                    this.rerenderIndex += 1
                    this.videoHasEndedOnce = true
                    this.showContinueAfterVideo = true
                }
            },
            continueAfterVideoWithQuestions() {
                this.showQuestionsAfterVideo = true
                this.showContinueAfterVideo = false
                this.startTimer()
            },
            initQuiz() {
                this.options.controls = ['play-large', 'current-time', 'mute', 'volume', 'fullscreen']
                if (this.$store.getters.isPreviewMode) {
                    this.options.controls = ['progress', ...this.options.controls]
                }
                if (this.task && this.task.taskType && this.task.taskType.alias.startsWith('QUIZ')) {
                    quizService.getQuiz(this.task.quizzes[0].id).then((res) => {
                        this.quiz = res.data
                        if (this.task.taskType.alias !== 'QUIZ_VIDEO') {
                            this.startTimer()
                        }
                    })
                }
            },
            selectAnswer(answer, questionIndex, totalQuestions) {
                if (this.selectedAnswer) {
                    return false
                }
                if (answer.correct) {
                    this.correctAnswers++
                }
                this.selectedAnswer = answer
            },
            answerIsVisible(answer) {
                return (this.selectedAnswer && answer.correct) || (this.selectedAnswer === answer && !answer.correct)
            },
            submitPoints(timeEnded) {
                if (!timeEnded) {
                    eventBus.$emit('killTimer', { taskId: this.task.id })
                }
                this.finishedQuestionsCount += 1
                setTimeout(() => {
                    let points
                    points = Math.round(this.task.points * this.correctAnswers / this.quiz.questions.length)
                    let finished = true
                    missionService.updateTask(this.mission, this.task, points, finished, this.$router)
                }, 2000)
            },
            next() {
                if (!this.selectedAnswer) {
                    return false
                }
                this.finishedQuestionsCount += 1
                this.activeQuestionIndex = Math.min(this.activeQuestionIndex + 1, this.quiz.questions.length - 1)
                this.selectedAnswer = null
            },
            startTimer() {
                if (this.task) {
                    let taskId = parseInt(this.$route.params.taskId)
                    eventBus.$emit('startTimer', { taskId: taskId, duration: this.task.duration, isTonyGame: false })
                }
            },
            progressBars(completedCount, totalCount) {
                return completedCount / totalCount ? `${(completedCount / totalCount) * 100}%` : '0%'
            }
        }
    }
</script>

<style lang="scss" scoped>
.quiz-route-container {
    width: 95%;
    margin: 0 auto;
    padding: 0;
    height: 100%;
}

.quiz-box {
    margin-top: 2rem;
    background: rgb(0 0 0 / 40%);
}

.progress-bar-container {
    padding: 0.5rem;
}

.progress-bar-background {
    height: 20px;
    background-color: var(--white);
    border-radius: 20px;
    padding: 5px;
}

.progress-color {
    background-color: var(--info-color);
    height: 10px;
    border-radius: 20px;
    box-shadow: 5px 3px 27px -3px var(--black);
    transition: all 0.5s ease-in-out;
}

.next-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
}

.video {
    height: auto;
    width: 100%;
}

.owl-bg {
    background: var(--secondary-color);
    padding: 0.5rem;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.image-answer-container {
    display: inline-block;
    margin-left: -40px;
    margin-right: -40px;
}

.question-header {
    font-weight: 700;
    color: var(--white);
    font-size: 1.2em;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 20px;
}

.button {
    padding: 8px 25px;
    font-size: 100%;
    border-radius: 20px;
    border: 1px solid var(--white);
    background: rgb(0 0 0 / 30%);
    color: var(--white);
    outline: none;
}

.button:hover {
    color: var(--secondary-color);
    outline: none;
}

.button:active {
    outline: none;
}

.question-number-head {
    font-weight: 700;
    color: var(--white);
    font-size: 14px;
    text-align: center;
}

.question-number-head.desktop {
    display: none;
}

.points-text-1 {
    font-weight: 700;
    color: var(--main-content-text);
    font-size: 25px;
    text-align: center;
}

.points-text-2 {
    font-weight: 700;
    color: var(--secondary-color);
    font-size: 2.25rem;
    text-align: center;
}

.quiz-text {
    color: var(--white);
    font-size: 1.125rem;
    text-align: left;
}

.quiz-instructions {
    display: none;
}

.not-clickable {
    pointer-events: none;
}

.disabled {
    pointer-events: none;
    cursor: default;
}

@media screen and (min-width: 768px) {
    .quiz-instructions {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: var(--main-content-text);
        font-size: 1.125rem;
        max-width: 300px;
        margin: 0 auto;
    }

    .question-number-head.mobile {
        display: none;
    }

    .question-number-head.desktop {
        display: block;
    }
}

@media screen and(min-width: 992px) {
    .route-content {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: 20% 700px 20%;
        grid-gap: 1rem;
        justify-content: center;
    }

    .quiz-instructions {
        display: flex !important;
        gap: 0.25rem;
    }

    .quiz-box {
        padding: 25px;
        margin-top: 0;
    }
}

@media screen and(min-width: 1600px) {
    .route-content {
        grid-template-columns: 20% 900px 20%;
    }
}

</style>
