<template>
    <b-container>
        <b-row class="justify-content-center task-number">
            {{ $t('taskIntro.title') }} {{ taskIndex + 1 }}
        </b-row>
        <b-row class="justify-content-center">

            <span class="task-title">{{ task.alias }}</span>

        </b-row>
        <hr>
        <b-row class="justify-content-center">
            <div class="task-text w-75">
                <span>{{ task.instructions }}</span>
            </div>
        </b-row>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        name: 'TaskIntro',
        mixins: [config],
        props: {
            taskIndex: {
                type: Number,
                default: 0
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return (missionId && taskId) ? this.$store.getters.getTask(missionId, taskId) : null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .task-number {
        font-weight: 700;
        font-size: 0.875rem;
        color: var(--white);
    }

    .task-title {
        font-weight: 700;
        font-size: 1.5rem;
        color: var(--white);
        margin: 1rem 0;
    }

    .task-text {
        font-size: 1.1rem;
        color: var(--white);
    }

    @media screen and (min-width: 768px) {
        .task-number {
            margin-top: 80px;
        }

        .task-title {
            margin-top: 25px;
            font-size: 2.25rem;
        }

        .task-text {
            font-size: 18.2px;
            margin-top: 40px;
            color: var(--white);
        }
    }
</style>
