<template>
    <div v-if="task && task.taskType" class="task">
        <task-top-bar />
        <b-container
            v-if="showInstructions"
            class="instructions-container px-3"
        >
            <b-row>
                <b-col class="margin-elements">
                    <span class="points-text-1 mr-2"><font-awesome-icon :icon="[ 'fas', 'plus-circle' ]" /></span><span class="points-text-2">{{ task.points }}</span><span class="ml-2 points-text-1">{{ $t('quiz.points') }}</span>
                </b-col>
                <b-col lg="6" class="intro-box">
                    <task-intro :task-index="taskIndex" />
                    <button
                        class="start-button"
                        @click="start()"
                    > {{ task.taskButtonText }} </button>
                </b-col>
                <b-col />
            </b-row>
        </b-container>
        <div v-else class="game-wrapper" :class="{'full-height': isGame}">
            <game v-if="isGame" />
            <action-read v-if="task.taskType.alias === 'ACTION_READ'" />
            <quiz v-if="isQuiz && !isDailyQuiz" />
            <button
                v-if="isCheatMode"
                class="earn-max"
                @click="submitMaxPoints()"
            > Earn Max Points </button>
        </div>
    </div>

</template>

<script>
    import missionService from '@/api/mission'
    import Quiz from '@/components/Quiz'
    import Game from '@/components/games/Game'
    import ActionRead from '@/components/ActionRead'
    import TaskTopBar from '@/components/TaskTopBar'
    import TaskIntro from '@/components/TaskIntro'
    import eventBus from '@/utils/event-bus'

    export default {
        name: 'TaskRoute',
        components: {
            Quiz,
            Game,
            ActionRead,
            TaskTopBar,
            TaskIntro
        },
        data() {
            return {
                showInstructions: true
            }
        },
        computed: {
            isQuiz() {
                return this.task && this.task.taskType && this.task.taskType.alias.startsWith('QUIZ')
            },
            isDailyQuiz() {
                return this.task && this.task.taskType && this.task.taskType.alias === 'QUIZ_DAILY'
            },
            isGame() {
                if (!this.task || !this.task.taskType) {
                    return false
                }
                return this.task.taskType.alias.startsWith('GAME')
            },
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            taskIndex() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTaskIndex(missionId, taskId)
            },
            isCheatMode() {
                return this.$store.getters.isCheatMode
            }
        },
        beforeDestroy() {
            window.removeEventListener('popstate', function() { })
        },
        methods: {
            start() {
                this.showInstructions = false
            },
            submitMaxPoints() {
                let maxPoints = this.task.points
                let finished = true
                eventBus.$emit('killTimer', { taskId: parseInt(this.$route.params.taskId) })
                missionService.updateTask(this.mission, this.task, maxPoints, finished, this.$router)
            }
        }
    }
</script>

<style lang="scss" scoped>
.task {
    flex-grow: 1;
    min-height: 100% !important;
}

.game-wrapper {
    padding-top: 2rem;
}

.game-wrapper.full-height {
    padding-top: 0;
    min-height: 100vh;
}

.intro-box {
    background: rgb(0 0 0 / 40%);
    width: 100%;
    padding: 1rem 0;
    margin: 0 auto;
}

.points-text-1 {
    font-weight: 700;
    color: var(--main-content-text);
    font-size: 1.5rem;
    text-align: center;
}

.points-text-2 {
    font-weight: 700;
    color: var(--secondary-color);
    font-size: 2.25rem;
    text-align: center;
}

.start-button {
    background: rgb(0 0 0 / 20%);
    font-size: 1rem;
    padding: 1rem 1.25rem;
    border: 1px solid var(--white);
    border-radius: 20px;
    margin-top: 2rem;
    width: 100%;
    max-width: 220px;
    color: var(--white);
    font-weight: 700;
    margin-bottom: 20px;
    outline: none;
    transition: all 0.1s ease-in-out;
}

.start-button:active {
    transform: scale(0.9);
}

.earn-max {
    position: absolute;
    bottom: 1%;
    left: 40%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
    .instructions-container {
        margin-top: 2rem;
    }

    .start-button {
        margin-top: 3rem;
    }

    .earn-max {
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
    }
}

</style>
