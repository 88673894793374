<template>
    <iframe
        id="gameIframe"
        title="Game Server Iframe"
        :src="iframeSrc"
        class="iframe-box"
        allow="fullscreen"
    />
</template>

<script>
    import missionService from '@/api/mission'
    import config from '@/mixins/config'
    import eventBus from '@/utils/event-bus'
    export default {
        name: 'Game',
        mixins: [config],
        data() {
            return {
                locationPath: window.location.protocol + '//' + window.location.host
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            mission() {
                return this.$store.getters.getMission(parseInt(this.$route.params.missionId))
            },
            lang() {
                return this.$store.getters.getLanguage
            },
            iframeSrc() {
                if (this.task) {
                    return `${this.config.gameServerHost}/gaiming/games/${this.task.id}?targetOrigin=${this.locationPath}&apiHost=${this.config.host}&lang=${process.env.VUE_APP_I18N_LOCALE}`
                } else {
                    return null
                }
            }
        },
        created() {
            window.addEventListener('message', this.messageEventHandler)
        },
        beforeDestroy() {
            this.closeFrame()
            window.removeEventListener('message', this.messageEventHandler)
        },
        mounted() {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
            window.addEventListener('resize', () => {
                const vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            })

            const element = document.getElementById('gameIframe')
            element.scrollIntoView({ block: 'end', inline: 'nearest' })
        },
        methods: {
            messageEventHandler(event) {
                if (!this.config.gameServerHost) {
                    return
                }
                const json = event.data
                if (!this.task) {
                    return
                }

                if (json.action === 'finish') {
                    eventBus.$emit('killTimer', { taskId: this.task.id })
                    if (!this.task.completed) {
                        missionService.updateTask(this.mission, this.task, json.score, true, this.$router)
                    } else {
                        eventBus.$emit('openTaskEndModal', { task: this.task, pointsWon: 0, isLastTask: this.isLastTask(this.mission, this.task.id), missionId: this.mission.id, isPlayAgain: true })
                        this.$router.push(`/missions/${this.mission.id}`)
                    }
                } else if (json.action === 'start') {
                    eventBus.$emit('startTimer', { taskId: this.task.id, duration: this.task.duration, isTonyGame: true })
                } else if (json.action === 'close') {
                    this.closeFrame()
                }
            },
            isLastTask(mission, taskId) {
                let index = 0
                for (let task of mission.tasks) {
                    if (task.id === taskId) {
                        break
                    }
                    index++
                }
                return index === mission.tasks.length - 1
            },
            closeFrame() {
                const frame = document.getElementById('gameIframe')
                if (frame) {
                    frame.remove()
                }
            }
        }
    }
</script>
<style scoped>
.iframe-box {
    border: none;
    height: 100vh;
    width: 100%;
}
</style>
